import React, { useState, useEffect, useContext } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { motion, AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

import Spinner from "../../Spinner";
import { ToastContainer, toast } from "react-toastify";
import API from "../../../services/interceptor";
import Loading from "../../Loading";
import { AuthContext } from "../../../context/AuthContext";

const OPDList = () => {
  const { getOPDData, opdData, opdLoading, opdError, setOpdData } =
    useContext(AuthContext);

  const navigate = useNavigate(); // Initialize useNavigate

  // Filter and sorting states
  const [searchTerm, setSearchTerm] = useState("");
  const [sortDate, setSortDate] = useState("desc");

  // State to manage which tiles are expanded
  const [expandedTiles, setExpandedTiles] = useState({});

  const toggleTile = (id) => {
    setExpandedTiles((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  const handleCheckup = (id) => {
    // Handle the checkup action, e.g., navigate to checkup page
    console.log(`Checkup for OPD ID: ${id}`);
    navigate(`/dashboard/checkup/${id}`); // Navigate to Checkup component with OPD ID
  };

  const handleEdit = (id) => {
    // Handle the edit action, e.g., open edit form
    console.log(`Edit OPD ID: ${id}`);
    navigate(`/dashboard/edit-opd/${id}`); // Navigate to EditOpd component with OPD ID
  };

  // Handle deleting a user
  const handleDelete = async (id) => {
    const confirmDelete = window.confirm(
      "Are you sure you want to delete this OPD? This action cannot be undone."
    );
    if (!confirmDelete) return;

    try {
      await API.delete(
        `${process.env.REACT_APP_API_URL}/api/doctor/delete-opd-patientrecord/${id}`
      );
      toast.success("OPD deleted successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        theme: "colored",
      });
      // Update the state to remove the deleted OPD
      setOpdData((prevData) => prevData.filter((opd) => opd._id !== id));
    } catch (err) {
      console.error("Error deleting OPD:", err);
      toast.error(
        err.response && err.response.data.message
          ? err.response.data.message
          : "Failed to delete OPD.",
        {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          theme: "colored",
        }
      );
    }
  };
  useEffect(() => {
    if (!opdData.length) {
      getOPDData();
    }
  }, [opdData, handleDelete]);

  if (opdLoading) {
    return <Loading />;
  }

  if (opdError) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-50">
        <div className="text-red-500 text-xl">Failed to load data.</div>
      </div>
    );
  }
  const formatDate = (date) => {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0'); // Ensure 2 digits (e.g. 01, 02)
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = d.getFullYear();
    
    return `${day}/${month}/${year}`;
  };
  

  const filteredOPDData = opdData
  .filter(
    (opd) =>
      opd.userDetails?.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      opd.opdNumber.toLowerCase().includes(searchTerm.toLowerCase())
  )
  .sort((a, b) => {
    // Sorting by Date
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);

    // Handle sorting by Date
    if (sortDate === "asc") {
      if (dateA < dateB) return -1;
      if (dateA > dateB) return 1;
      return 0;
    }
    if (sortDate === "desc") {
      if (dateA < dateB) return 1;
      if (dateA > dateB) return -1;
      return 0;
    }
    
    return 0;
  });



  // Animation variants for the tile expansion
  const variants = {
    collapsed: { height: 0, opacity: 0, overflow: "hidden" },
    expanded: { height: "auto", opacity: 1 },
  };

  // Animation variants for the tile container
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: (i) => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.05 },
    }),
  };

  return (
    <div className="min-h-screen  md:p-6">
      <div className="mx-auto p-4 bg-white shadow-md rounded-lg ">
        <h1 className="text-3xl font-bold text-center mb-8 text-gray-800">
          OPD List
        </h1>
          {/* Filters Section */}
          <div className="flex flex-wrap gap-4 mb-6">
          {/* Search Filter */}
          <div className="flex items-center gap-2 w-full sm:w-auto">
            <label htmlFor="search" className="text-gray-700 font-medium">
              Search:
            </label>
            <div className="flex items-center bg-gray-100 border rounded-lg overflow-hidden">
              <input
                id="search"
                type="text"
                className="p-2 w-56 sm:w-80 border-none focus:ring-2 focus:ring-blue-500 rounded-l-lg"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search by name or OPD number"
              />
              <span className="p-2 text-gray-500">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  className="w-5 h-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 11a4 4 0 10-8 0 4 4 0 008 0zM12 7a5 5 0 110 10 5 5 0 010-10z"
                  />
                </svg>
              </span>
            </div>
          </div>

          {/* Sort by Date */}
          <div className="flex items-center gap-2 w-full sm:w-auto">
            <label htmlFor="dateSort" className="text-gray-700 font-medium">
              Sort by Date:
            </label>
            <button
              id="dateSort"
              className="flex items-center gap-1 px-4 py-2 bg-white border rounded-lg shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
              onClick={() => setSortDate(sortDate === "asc" ? "desc" : "asc")}
            >
              {sortDate === "asc" ? (
                <>
                  <span>From Starting</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </>
              ) : (
                <>
                  <span>Latest First </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
                  </svg>
                </>
              )}
            </button>
          </div>

          
        </div>
        <div className="space-y-6 ">
          {filteredOPDData.map((opd, index) => (
            <motion.div
              key={opd._id}
              className={`border border-gray-200 rounded-lg shadow-sm ${
                expandedTiles[opd._id] ? "bg-slate-50" : "bg-white"
              }`}
              initial="hidden"
              animate="visible"
              custom={index}
              variants={containerVariants}
            >
              {/* Header */}
              <div
                className="flex justify-between items-center p-4 cursor-pointer"
                onClick={() => toggleTile(opd._id)}
              >
                <div>
                  <p className="text-xl font-semibold text-gray-800">
                    OPD Number:{" "}
                    <span className="text-blue-600">{opd.opdNumber}</span>
                  </p>
                  <p className="text-md text-gray-600">
                    <span className="font-medium">Name:</span>{" "}
                    {opd.userDetails?.name}
                  </p>
                  <p className="text-md text-gray-600">
                    <span className="font-medium">Chief Complaint:</span>{" "}
                    {opd.checkupInfo}
                  </p>
                  <p className="text-md text-gray-600">
                    <span className="font-medium">Age:</span> {opd.age}
                  </p>
                  <p className="text-sm text-gray-500">
                    Created At: {formatDate(opd.createdAt).toLocaleString()}
                  </p>
                </div>
                <div>
                  {expandedTiles[opd._id] ? (
                    <FaChevronUp className="h-6 w-6 text-gray-600" />
                  ) : (
                    <FaChevronDown className="h-6 w-6 text-gray-600" />
                  )}
                </div>
              </div>

              {/* Expanded Content with Animation */}
              <AnimatePresence>
                {expandedTiles[opd._id] && (
                  <motion.div
                    className="px-4 pb-4"
                    initial="collapsed"
                    animate="expanded"
                    exit="collapsed"
                    variants={variants}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <div className="mt-4 border-t pt-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Patient Details */}
                        <div>
                          <h2 className="text-lg font-semibold mb-2 text-gray-700">
                            Patient Details
                          </h2>
                          <div className="space-y-1 text-gray-600">
                            <p>
                              <span className="font-medium">Email:</span>{" "}
                              {opd.user?.email}
                            </p>
                            <p>
                              <span className="font-medium">Contact:</span>{" "}
                              {opd.userDetails?.contact?.mobile}
                            </p>
                            <p>
                              <span className="font-medium">Address:</span>{" "}
                              {opd.userDetails?.contact?.address}
                            </p>
                            <p>
                              <span className="font-medium">
                                Date of Birth:
                              </span>{" "}
                              {new Date(
                                opd.userDetails?.dateOfBirth
                              ).toLocaleDateString()}
                            </p>
                            <p>
                              <span className="font-medium">Gender:</span>{" "}
                              {opd.userDetails?.gender}
                            </p>
                          </div>
                        </div>
                        {/* Emergency Contact */}
                        <div className="mt-6">
                          <h2 className="text-lg font-semibold mb-2 text-gray-700">
                            Emergency Contact
                          </h2>
                          <div className="space-y-1 text-gray-600">
                            <p>
                              <span className="font-medium">Name:</span>{" "}
                              {opd.userDetails?.emergencyContact?.name}
                            </p>
                            <p>
                              <span className="font-medium">Relationship:</span>{" "}
                              {opd.userDetails?.emergencyContact?.relationship}
                            </p>
                            <p>
                              <span className="font-medium">Phone:</span>{" "}
                              {opd.userDetails?.emergencyContact?.phone}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="md:mt-5 grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Medical Details */}
                        <div>
                          <h2 className="text-lg font-semibold mb-2 text-gray-700">
                            Medical Details
                          </h2>
                          <div className="space-y-1 text-gray-600">
                            <p>
                              <span className="font-medium">Blood Group:</span>{" "}
                              {opd.medicalDetails.bloodGroup}
                            </p>
                            <p>
                              <span className="font-medium">
                                Medical History:
                              </span>{" "}
                              {opd.medicalDetails.medicalHistory}
                            </p>

                            <div>
                              <span className="font-medium">
                                Lifestyle & Habits:
                              </span>
                              <ul className="list-disc list-inside ml-4">
                                <li>
                                  Smoking Status:{" "}
                                  {
                                    opd.medicalDetails?.lifestyleAndHabits?.smokingStatus
                                  }
                                </li>
                                <li>
                                  Alcohol Use:{" "}
                                  {
                                    opd.medicalDetails?.lifestyleAndHabits?.alcoholUse
                                  }
                                </li>
                                <li>
                                  Tobacco:{" "}
                                  {
                                    opd.medicalDetails?.lifestyleAndHabits?.tobacco
                                  }
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        {/* Additional Details */}
                        <div className="mt-6">
                          <h2 className="text-lg font-semibold mb-2 text-gray-700">
                            Additional Information
                          </h2>
                          <div className="space-y-2 text-gray-600">
                            <div>
                              <span className="font-medium">Medications:</span>
                              {opd.medicalDetails.medications &&
                              opd.medicalDetails.medications.length > 0 ? (
                                <ul className="list-disc list-inside ml-4">
                                  {opd.medicalDetails.medications.map(
                                    (med, idx) => (
                                      <li key={idx}>
                                        {med?.name} - {med?.dosage} -{" "}
                                        {med?.frequency}
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <p className="ml-4">No medications listed.</p>
                              )}
                            </div>
                            <div>
                              <span className="font-medium">Allergies:</span>
                              {opd.medicalDetails.allergies &&
                              opd.medicalDetails.allergies.length > 0 ? (
                                <ul className="list-disc list-inside ml-4">
                                  {opd.medicalDetails.allergies.map(
                                    (allergy, idx) => (
                                      <li key={idx}>
                                        {allergy.allergen} - {allergy.reaction}{" "}
                                        ({allergy.severity})
                                      </li>
                                    )
                                  )}
                                </ul>
                              ) : (
                                <p className="ml-4">No allergies listed.</p>
                              )}
                            </div>
                            <p>
                              <span className="font-medium">
                                Past Dental History:
                              </span>{" "}
                              {opd.medicalDetails.pastDentalHistory ||
                                "Not provided"}
                            </p>
                            <p>
                              <span className="font-medium">Notes:</span>{" "}
                              {opd.medicalDetails.notes ||
                                "No additional notes."}
                            </p>
                          </div>
                        </div>

                        {/* Emergency Contact
                                                <div className="mt-6">
                                                    <h2 className="text-lg font-semibold mb-2 text-gray-700">Emergency Contact</h2>
                                                    <div className="space-y-1 text-gray-600">
                                                        <p>
                                                            <span className="font-medium">Name:</span> {opd.userDetails.emergencyContact.name}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Relationship:</span>{' '}
                                                            {opd.userDetails.emergencyContact.relationship}
                                                        </p>
                                                        <p>
                                                            <span className="font-medium">Phone:</span> {opd.userDetails.emergencyContact.phone}
                                                        </p>
                                                    </div>
                                                </div> */}
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <button
                          className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 focus:outline-none"
                          onClick={() => handleCheckup(opd._id)}
                        >
                          Checkup
                        </button>
                        <button
                          className="bg-green-500 text-white px-3 py-1 rounded-md hover:bg-green-600 focus:outline-none"
                          onClick={() => handleEdit(opd._id)}
                        >
                          Edit
                        </button>
                        <button
                          className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 focus:outline-none"
                          onClick={() => handleDelete(opd._id)}
                        >
                          Delete
                        </button>
                      </div>
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OPDList;
