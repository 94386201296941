// src/App.js
import React, { useState } from 'react';

// Tooth Component
const Tooth = ({ id, upperImg, frontImg, onSelect, isSelected }) => {
  return (
    <div
      className={`cursor-pointer p-2 border ${
        isSelected ? 'border-blue-500' : 'border-gray-300'
      } rounded-lg hover:border-blue-400 transition duration-300`}
      onClick={() => onSelect(id)}
      title="Select Tooth"
    >
      <div className="flex flex-col items-center">
        {/* Upper Image */}
        <img
          src={upperImg}
          alt={`Tooth ${id} upper`}
          className="w-16 h-16 object-contain mb-2"
        />
        {/* Front Image */}
        <img
          src={frontImg}
          alt={`Tooth ${id} front`}
          className="w-16 h-16 object-contain mb-2"
        />
        {/* Tooth Number */}
        <div className="bg-gray-100 p-1 rounded-full">
          <span className="text-sm font-semibold text-gray-700">{id}</span>
        </div>
      </div>
    </div>
  );
};

// teethData.js
const teethData = {
    upperLeftTeeth: [
      // Teeth 18 to 11
      {
        id: 18,
        upperImg:
          '/ToothGrid/tooth-18-upper.png',
        frontImg:
          '/ToothGrid/tooth-18-front.png',
      },
      {
        id: 17,
        upperImg:
          '/ToothGrid/tooth-17-upper.png',
        frontImg:
          '/ToothGrid/tooth-17-front.png',
      },
      {
        id: 16,
        upperImg:
          '/ToothGrid/tooth-16-upper.png',
        frontImg:
          '/ToothGrid/tooth-16-front.png',
      },
      {
        id: 15,
        upperImg:
          '/ToothGrid/tooth-15-upper.png',
        frontImg:
          '/ToothGrid/tooth-15-front.png',
      },
      {
        id: 14,
        upperImg:
          '/ToothGrid/tooth-14-upper.png',
        frontImg:
          '/ToothGrid/tooth-14-front.png',
      },
      {
        id: 13,
        upperImg:
          '/ToothGrid/tooth-13-upper.png',
        frontImg:
          '/ToothGrid/tooth-13-front.png',
      },
      {
        id: 12,
        upperImg:
          '/ToothGrid/tooth-12-upper.png',
        frontImg:
          '/ToothGrid/tooth-12-front.png',
      },
      {
        id: 11,
        upperImg:
          '/ToothGrid/tooth-11-upper.png',
        frontImg:
          '/ToothGrid/tooth-11-front.png',
      },
    ],
    upperRightTeeth:[
      // Teeth 21 to 28
      {
        id: 21,
        upperImg:
          '/ToothGrid/tooth-21-upper.png',
        frontImg:
          '/ToothGrid/tooth-21-front.png',
      },
      {
        id: 22,
        upperImg:
          '/ToothGrid/tooth-22-upper.png',
        frontImg:
          '/ToothGrid/tooth-22-front.png',
      },
      {
        id: 23,
        upperImg:
          '/ToothGrid/tooth-23-upper.png',
        frontImg:
          '/ToothGrid/tooth-23-front.png',
      },
      {
        id: 24,
        upperImg:
          '/ToothGrid/tooth-24-upper.png',
        frontImg:
          '/ToothGrid/tooth-24-front.png',
      },
      {
        id: 25,
        upperImg:
          '/ToothGrid/tooth-25-upper.png',
        frontImg:
          '/ToothGrid/tooth-25-front.png',
      },
      {
        id: 26,
        upperImg:
          '/ToothGrid/tooth-26-upper.png',
        frontImg:
          '/ToothGrid/tooth-26-front.png',
      },
      {
        id: 27,
        upperImg:
          '/ToothGrid/tooth-27-upper.png',
        frontImg:
          '/ToothGrid/tooth-27-front.png',
      },
      {
        id: 28,
        upperImg:
          '/ToothGrid/tooth-28-upper.png',
        frontImg:
          '/ToothGrid/tooth-28-front.png',
      },
    ],
    lowerLeftTeeth: [
      // Teeth 48 to 41
      {
        id: 48,
        upperImg:
          '/ToothGrid/tooth-48-front.png',
        frontImg:
          '/ToothGrid/tooth-48-upper.png',
      },
      {
        id: 47,
        upperImg:
          '/ToothGrid/tooth-47-front.png',
        frontImg:
          '/ToothGrid/tooth-47-upper.png',
      },
      {
        id: 46,
        upperImg:
          '/ToothGrid/tooth-46-front.png',
        frontImg:
          '/ToothGrid/tooth-46-upper.png',
      },
      {
        id: 45,
        upperImg:
          '/ToothGrid/tooth-45-front.png',
        frontImg:
          '/ToothGrid/tooth-45-upper.png',
      },
      {
        id: 44,
        upperImg:
          '/ToothGrid/tooth-44-front.png',
        frontImg:
          '/ToothGrid/tooth-44-upper.png',
      },
      {
        id: 43,
        upperImg:
          '/ToothGrid/tooth-43-front.png',
        frontImg:
          '/ToothGrid/tooth-43-upper.png',
      },
      {
        id: 42,
        upperImg:
          '/ToothGrid/tooth-42-front.png',
        frontImg:
          '/ToothGrid/tooth-42-upper.png',
      },
      {
        id: 41,
        upperImg:
          '/ToothGrid/tooth-41-front.png',
        frontImg:
          '/ToothGrid/tooth-41-upper.png',
      },],
      lowerRightTeeth: [
      // Teeth 31 to 38
      {
        id: 31,
        upperImg:
          '/ToothGrid/tooth-31-front.png',
        frontImg:
          '/ToothGrid/tooth-31-upper.png',
      },
      {
        id: 32,
        upperImg:
          '/ToothGrid/tooth-32-front.png',
        frontImg:
          '/ToothGrid/tooth-32-upper.png',
      },
      {
        id: 33,
        upperImg:
          '/ToothGrid/tooth-33-front.png',
        frontImg:
          '/ToothGrid/tooth-33-upper.png',
      },
      {
        id: 34,
        upperImg:
          '/ToothGrid/tooth-34-front.png',
        frontImg:
          '/ToothGrid/tooth-34-upper.png',
      },
      {
        id: 35,
        upperImg:
          '/ToothGrid/tooth-35-front.png',
        frontImg:
          '/ToothGrid/tooth-35-upper.png',
      },
      {
        id: 36,
        upperImg:
          '/ToothGrid/tooth-36-front.png',
        frontImg:
          '/ToothGrid/tooth-36-upper.png',
      },
      {
        id: 37,
        upperImg:
          '/ToothGrid/tooth-37-front.png',
        frontImg:
          '/ToothGrid/tooth-37-upper.png',
      },
      {
        id: 38,
        upperImg:
          '/ToothGrid/tooth-38-front.png',
        frontImg:
          '/ToothGrid/tooth-38-upper.png',
      },
    ],
  };
  
  

// ToothGrid Component
const ToothGrid = () => {
  const [selectedTeeth, setSelectedTeeth] = useState([]);

  const handleSelectTooth = (id) => {
    setSelectedTeeth((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((toothId) => toothId !== id)
        : [...prevSelected, id]
    );
  };

  return (
    <div className="w-full overflow-x-auto">
      {/* Upper Teeth */}
      <div className=" min-w-max">
        <table className="w-full border-collapse">
          <tbody>
            <tr>
              {/* Upper Left Teeth */}
              <td className="border-r-4 border-b-2  border-gray-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>
                      {teethData.upperLeftTeeth.map((tooth) => (
                        <td key={tooth.id} className="p-1">
                          <Tooth
                            id={tooth.id}
                            upperImg={tooth.upperImg}
                            frontImg={tooth.frontImg}
                            onSelect={handleSelectTooth}
                            isSelected={selectedTeeth.includes(tooth.id)}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
              {/* Upper Right Teeth */}
              <td className="border-l-2 border-b-2  border-gray-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>
                      {teethData.upperRightTeeth.map((tooth) => (
                        <td key={tooth.id} className="p-1">
                          <Tooth
                            id={tooth.id}
                            upperImg={tooth.upperImg}
                            frontImg={tooth.frontImg}
                            onSelect={handleSelectTooth}
                            isSelected={selectedTeeth.includes(tooth.id)}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border-b-2 border-gray-300"></td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Lower Teeth */}
      <div className=" min-w-max">
        <table className="w-full border-collapse">
          <tbody>
            <tr>
              {/* Lower Left Teeth */}
              <td className="border-r-4 border-t-2 border-gray-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>
                      {teethData.lowerLeftTeeth.map((tooth) => (
                        <td key={tooth.id} className="p-1">
                          <Tooth
                            id={tooth.id}
                            upperImg={tooth.upperImg}
                            frontImg={tooth.frontImg}
                            onSelect={handleSelectTooth}
                            isSelected={selectedTeeth.includes(tooth.id)}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
              {/* Lower Right Teeth */}
              <td className=" border-t-2 border-gray-300">
                <table className="w-full border-collapse">
                  <tbody>
                    <tr>
                      {teethData.lowerRightTeeth.map((tooth) => (
                        <td key={tooth.id} className="p-1">
                          <Tooth
                            id={tooth.id}
                            upperImg={tooth.upperImg}
                            frontImg={tooth.frontImg}
                            onSelect={handleSelectTooth}
                            isSelected={selectedTeeth.includes(tooth.id)}
                          />
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </td>
              <td className="border-b-2 border-gray-300"></td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* Selected Teeth Display */}
      {selectedTeeth.length > 0 && (
        <div className="mt-6 text-center">
          <h2 className="text-lg font-semibold mb-2">Selected Teeth:</h2>
          <div className="flex justify-center flex-wrap">
            {selectedTeeth.map((id) => (
              <span
                key={id}
                className="m-1 px-3 py-1 bg-blue-100 text-blue-700 rounded-full"
              >
                {id}
              </span>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ToothGrid;